import HttpService from "./HttpService";

export default class SalesChannelManagementService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get groups
   *
   *
   * @returns {Promise}
   */
  getGroups() {
    return this.httpService.get("/v1/sales_channels_groups");
  }

  createGroup(parameters) {
    return this.httpService.post("/v1/sales_channels_groups", parameters);
  }

  deleteGroup(parameters) {
    return this.httpService.delete(
      "/v1/sales_channels_groups/" + parameters.id
    );
  }

  updateGroup(parameters) {
    return this.httpService.put(
      "/v1/sales_channels_groups/" + parameters.id,
      parameters
    );
  }

  ///////////////////////////////////////////////////////////////

  getSalesChannels() {
    return this.httpService.get("/v1/sales_channels");
  }

  addSalesChannel(parameters) {
    return this.httpService.post("/v1/sales_channels", parameters);
  }

  deleteSalesChannel(parameters) {
    return this.httpService.delete("/v1/sales_channels/" + parameters.id);
  }

  updateSalesChannel(parameters) {
    return this.httpService.put(
      "/v1/sales_channels/" + parameters.id,
      parameters
    );
  }
}
