<template>
  <div>
    <LoadingPage v-show="loaded" />
    <div v-show="!loaded" class="page">
      <div class="heading d-md-flex justify-content-md-between mb-4">
        <div class="heading-left">
          <h4 class="heading-4">{{ $t("salesChannelsPage.title") }}</h4>
        </div>
        <div class="heading-right">
          <h4 class="heading-4 mb-3">
            {{ $t("salesChannelsPage.newSalesChannel") }}
          </h4>
          <div class="d-flex flex-column">
            <NbButton
              size="sm"
              icon="plus"
              class="mb-2 text-left"
              data-toggle="modal"
              data-target="#modalAddSalesChannel"
              @click="initializeNewSalesChannel"
            >
              {{ $t("salesChannelsPage.addSalesChannel") }}
            </NbButton>
          </div>
        </div>
      </div>

      <NbTabs :tabs="tabsOptions" @switchTab="switchTab" />

      <div class="mt-4">
        <NbTablev2
          tableOf="salesChannelsTable"
          namespace="sales_channels"
          ref="salesChannelsTable"
          :allFields="allFields"
          :fields="fields"
          @reloadFields="fields = $event"
          @total="totalItems = $event"
        >
          <template #cell(name)="data">
            <div
              class="link-1"
              data-toggle="modal"
              data-target="#modalViewSalesChannel"
              @click="copySalesChannelFromTable(data.item)"
            >
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(actions)="data">
            <NbButton
              :disabled="data.item.is_public"
              :class="data.item.is_public ? 'not-allowed' : ''"
              variant="tertiary"
              icon="edit"
              data-toggle="modal"
              data-target="#modalUpdateSalesChannel"
              @click="copySalesChannelFromTable(data.item)"
            >
              {{ $t("edit") }}
              <NbHelpText
                v-if="data.item.is_public"
                id="update-popover"
                class="ml-1"
                size="sm"
                placement="topright"
              >
                You are not authorized to update this Sales Channel
              </NbHelpText>
            </NbButton>
            <NbButton
              variant="tertiary"
              icon="trash"
              data-toggle="modal"
              data-target="#modalDeleteSalesChannel"
              @click="indexSalesChannelToDelete = data.item"
            >
              {{ $t("delete") }}
            </NbButton>
          </template>
        </NbTablev2>

        <NbFooter
          class="mt-1"
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
    </div>

    <!-- modals -->
    <NbModal id="modalAddSalesChannel" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("salesChannelsPage.createSalesChannel") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="name-new"
            class="mb-3 w-48-5"
            :required="true"
            :name="$t('name')"
            :error="errorsSalesChannel.name"
            :placeholder="$t('name')"
            v-model="newSalesChannel.name"
          />
          <NbTextInput
            id="email-new"
            class="mb-3 w-48-5"
            :name="$t('email')"
            :error="errorsSalesChannel.email"
            :placeholder="$t('email')"
            v-model="newSalesChannel.email"
          />
          <NbTextInput
            id="webhook-new"
            class="mb-3 w-100"
            :name="$t('settingsPage.webhook')"
            :error="errorsSalesChannel.webhook"
            :placeholder="$t('settingsPage.webhook')"
            v-model="newSalesChannel.webhook"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="addSalesChannels">
            {{ $t("create") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="modalUpdateSalesChannel" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("salesChannelsPage.updateSalesChannel") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="name"
            class="mb-3 w-48-5"
            :required="true"
            :name="$t('name')"
            :error="errorsSalesChannel.name"
            :placeholder="$t('name')"
            v-model="salesChannelToUpdate.name"
          />
          <NbTextInput
            id="email"
            class="mb-3 w-48-5"
            :name="$t('email')"
            :error="errorsSalesChannel.email"
            :placeholder="$t('email')"
            v-model="salesChannelToUpdate.email"
          />
          <NbTextInput
            id="webhook"
            class="mb-3 w-100"
            :name="$t('settingsPage.webhook')"
            :error="errorsSalesChannel.webhook"
            :placeholder="$t('settingsPage.webhook')"
            v-model="salesChannelToUpdate.webhook"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="salesChannelUpdate">
            {{ $t("update") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="modalViewSalesChannel" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("salesChannelsPage.salesChannelInfo") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="body-4">
          {{ $t("name") }}: <b>{{ salesChannelToUpdate.name }}</b>
        </div>
        <div class="body-4">
          {{ $t("email") }}: <b>{{ salesChannelToUpdate.email }}</b>
        </div>
        <div class="body-4">
          {{ $t("settingsPage.webhook") }}:
          <b>{{ salesChannelToUpdate.webhook }}</b>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("close") }}
          </NbButton>
          <NbButton
            :disabled="salesChannelToUpdate.is_public"
            data-dismiss="modal"
            data-toggle="modal"
            data-target="#modalUpdateSalesChannel"
          >
            {{ $t("update") }}
            <NbHelpText
              v-if="salesChannelToUpdate.is_public"
              id="update-popover"
              class="ml-1"
              placement="topright"
            >
              You are not authorized to update this Sales Channel
            </NbHelpText>
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal id="modalDeleteSalesChannel" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("confirmDelete", { val: "this Sales Channel" }) }}?
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal" aria-label="Close">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="deleteSalesChannels()">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- modals end -->
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import SalesChannelsManagementService from "@/services/SalesChannelManagementService.js";
import NProgress from "nprogress";

const salesChannelManagementService = new SalesChannelsManagementService();

export default {
  name: "SalesChannelsManagement",
  components: {
    LoadingPage,
    NbButton,
    NbTabs,
    NbTablev2,
    NbModal,
    NbTextInput,
    NbHelpText,
    NbFooter,
  },
  data() {
    return {
      loaded: true,
      fields: [],
      currentTable: "all",
      totalItems: -1,
      indexSalesChannelToDelete: {},
      salesChannelToUpdate: {},
      newSalesChannel: {
        name: "",
        email: "",
        webhook: "",
      },
      errorsSalesChannel: [],
    };
  },
  created() {
    this.fields = this.allFields;
    setTimeout(() => {
      this.loaded = false;
    }, 1000);
  },
  methods: {
    switchTab(tab) {
      if (tab !== this.currentTable) {
        this.currentTable = tab;
      }
    },
    addSalesChannels() {
      salesChannelManagementService
        .addSalesChannel(this.newSalesChannel)
        .then(() => {
          this.errorsSalesChannel = [];
          this.$refs.salesChannelsTable.getData();
          this.$helpers.closeModal("modalAddSalesChannel");
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsSalesChannel = response.data.messages[0];
          }
          NProgress.done();
        });
    },
    deleteSalesChannels() {
      salesChannelManagementService
        .deleteSalesChannel(this.indexSalesChannelToDelete)
        .then(() => {
          this.$refs.salesChannelsTable.getData();
          this.$helpers.closeModal("modalDeleteSalesChannel");
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsSalesChannel = response.data.messages[0];
          }
          this.$helpers.toast(
            this.errorsSalesChannel || "Sales channel deletion failed",
            "danger"
          );
          NProgress.done();
        });
    },
    salesChannelUpdate() {
      salesChannelManagementService
        .updateSalesChannel(this.salesChannelToUpdate)
        .then(() => {
          this.errorsSalesChannel = [];
          this.$refs.salesChannelsTable.getData();
          this.$helpers.closeModal("modalUpdateSalesChannel");
        })
        .catch(({ response }) => {
          if (
            response.data &&
            response.data.messages &&
            response.data.messages[0]
          ) {
            this.errorsSalesChannel = response.data.messages[0];
          }
          NProgress.done();
        });
    },
    initializeNewSalesChannel() {
      this.newSalesChannel.name = "";
      this.newSalesChannel.email = "";
      this.newSalesChannel.webhook = "";
      this.errorsSalesChannel = [];
    },
    copySalesChannelFromTable(item) {
      this.salesChannelToUpdate = item;
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("salesChannels"),
          found: this.totalItems,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        { key: "id", label: "ID" },
        { key: "name", label: this.$t("name") },
        {
          key: "actions",
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  .heading-right {
    min-width: 15.87rem;
  }
}
</style>
